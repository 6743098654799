import React from "react";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";
// import BackgroundBrundcrumb from "../../assets/images/IZB/IZB Website Skin/Forign Business/Untitled-1.png";
import SavePlusAccountComponent from "../../Components/SavePlusAccount/SavePlusAccountComponent";
// import img1 from "../../assets/images/IZB/IZB Website Skin/Forign Business/Untitled-2.png";
import bgimg from "../../assets/images/IZB/IZB Website Skin/IZB Save Plus Account/image_6.png";
import { AiFillCaretRight, AiOutlineRight } from "react-icons/ai";
import { useEffect, useState } from "react";
import Arrow_down from "../../assets/images/IZB/arrow_down.png";
import axios from 'axios';
import AdvisorsVideo from "../../Components/AdvisorsVideo/AdvisorsVideo";
import AllAccounts from "../../Components/AllAccounts/AllAccounts";
import ContactForm from "../../Components/ContactForm/ContactForm";
import CustomerCare from "../../Components/CustomerCare/CustomerCare";
import OurStores from "../../Components/OurStories/OurStores";

const ForexRates = () => {
  const BackgroundBrundcrumb = "https://techrowth.s3.eu-north-1.amazonaws.com/images/Forex_Savings_Banner.png";
const img1 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/Forex_Savings.png";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [rates, setRates] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchForexRates = async () => {
      const appId = 'c1018d855f1e40499a81ba0738bd09f0'; // Replace with your actual App ID
      const url = `https://openexchangerates.org/api/latest.json?app_id=${appId}`;
  
      try {
        const response = await axios.get(url);
        const { rates } = response.data;
        setRates(rates);
      } catch (error) {
        console.error('Error fetching forex rates:', error);
      }
    };
  
    fetchForexRates();
  }, []);

  useEffect(() => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Africa/Lusaka' };
    const currentDate = new Date().toLocaleDateString(undefined, options);
    setCurrentDate(currentDate);
  }, []);

  const accountContent =
    "Savings accounts can be opened for individuals in US Dollar/Pound Sterling/South African Rand/Euro. Cheque book facility is an option available. With this product, we also offer VISA Chip & Pin Debit Card facility, which is ideal for businessment/employees travelling frequently outside Zambia, who can draw money from any of the VISA enabled ATMs around the world.";
  return (
    <>
      <Breadcrumb
        Heading="Forex Rates"
        img={BackgroundBrundcrumb}
        show={true}
        btnTxt="Open an Account"
      />
   <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
  <div className="my-5 " style={{ textAlign: "center" }}>
    <h2 className="my-5 mx-3" style={{border:"1px solid", padding:"2vw", color:"white",background:"rgb(158, 27, 30)"}}>Indicative Forex Rates</h2>
    {currentDate && <h4 className="my-3 mx-3" style={{    color: "rgb(72, 72, 72)"}}>Updated As On: {currentDate}</h4>}

      <table className="my-3 " style={{ margin: "0 auto", textAlign: "center", borderCollapse: "collapse", border: "1px solid black" }}>
  <thead >
    <tr >
      <th  style={{ border: "1px solid black", padding: "1vw 3vw", background:"rgb(158, 27, 30)", color:"white" }}></th>
      <th style={{ border: "1px solid black", padding: "1vw 3vw", background:"rgb(158, 27, 30)", color:"white" }}>Buying (ZMW)</th>
      <th style={{ border: "1px solid black", padding: "1vw 3vw", background:"rgb(158, 27, 30)", color:"white" }}>Selling (ZMW)</th>
    </tr>
  </thead>

    <tbody>
 
    <tr>
      <td style={{ border: "1px solid black", padding: "1vw 3vw",  fontWeight:"500", color:"rgb(158, 27, 30)" }}>USD/ZMW
</td>
      <td style={{ border: "1px solid black", padding: "1vw 3vw" }}>26.3000</td>
      <td style={{ border: "1px solid black", padding: "1vw 3vw" }}>26.8000</td>
    </tr>
    <tr>
      <td style={{ border: "1px solid black", padding: "1vw 3vw",  fontWeight:"500", color:"rgb(158, 27, 30)" }}>ZAR/ZMW
</td>
      <td style={{ border: "1px solid black", padding: "1vw 3vw" }}>1.5049</td>
      <td style={{ border: "1px solid black", padding: "1vw 3vw" }}>1.5350</td>
    </tr>
    <tr>
      <td style={{ border: "1px solid black", padding: "1vw 3vw",  fontWeight:"500", color:"rgb(158, 27, 30)" }}>INR/ZMW
</td>
      <td style={{ border: "1px solid black", padding: "1vw 3vw" }}>0.3153</td>
      <td style={{ border: "1px solid black", padding: "1vw 3vw" }}>0.3216</td>
    </tr>
    <tr>
      <td style={{ border: "1px solid black", padding: "1vw 3vw",  fontWeight:"500", color:"rgb(158, 27, 30)" }}>GBP/ZMW
</td>
      <td style={{ border: "1px solid black", padding: "1vw 3vw" }}>34.4200</td>
      <td style={{ border: "1px solid black", padding: "1vw 3vw" }}>35.1000</td>
    </tr>
    <tr>
      <td style={{ border: "1px solid black", padding: "1vw 3vw",  fontWeight:"500", color:"rgb(158, 27, 30)" }}>EUR/ZMW
</td>
      <td style={{ border: "1px solid black", padding: "1vw 3vw" }}>28.8700</td>
      <td style={{ border: "1px solid black", padding: "1vw 3vw" }}>29.4400</td>
    </tr>
    <tr>
      <td style={{ border: "1px solid black", padding: "1vw 3vw",  fontWeight:"500", color:"rgb(158, 27, 30)" }}>GBP/USD
</td>
      <td style={{ border: "1px solid black", padding: "1vw 3vw" }}>1.2780</td>
      <td style={{ border: "1px solid black", padding: "1vw 3vw" }}>1.3315</td>
    </tr>
    <tr>
      <td style={{ border: "1px solid black", padding: "1vw 3vw",  fontWeight:"500", color:"rgb(158, 27, 30)" }}>EUR/USD
</td>
      <td style={{ border: "1px solid black", padding: "1vw 3vw" }}>1.0800</td>
      <td style={{ border: "1px solid black", padding: "1vw 3vw" }}>1.1072</td>
    </tr>
    <tr>
      <td style={{ border: "1px solid black", padding: "1vw 3vw",  fontWeight:"500", color:"rgb(158, 27, 30)" }}>USD/ZAR
</td>
      <td style={{ border: "1px solid black", padding: "1vw 3vw" }}>17.1100</td>
      <td style={{ border: "1px solid black", padding: "1vw 3vw" }}>17.9100</td>
    </tr>
    <tr>
      <td style={{ border: "1px solid black", padding: "1vw 3vw",  fontWeight:"500", color:"rgb(158, 27, 30)" }}>USD/INR
</td>
      <td style={{ border: "1px solid black", padding: "1vw 3vw" }}>82.9600</td>
      <td style={{ border: "1px solid black", padding: "1vw 3vw" }}>85.1700</td>
    </tr>
  </tbody>
</table>
<br/>

  
 
    <h6 className="my-5 mx-3" style={{border:"1px solid", padding:"2vw", color:"white",background:"rgb(158, 27, 30)"}}>The above rates are indicative and subject to change without notice. <br />Please call + 260 211 228245 / +260 211 238924 for your personalized Forex rates.</h6>
  </div>      
</div>

     

<AdvisorsVideo />
      <AllAccounts />
      <ContactForm />
      <CustomerCare />
      <OurStores />
    </>
  );
};

export default ForexRates;